import { useAuth0 } from '@auth0/auth0-react';
import { useApp } from '../../context/AppContext';
import { useState, useEffect } from 'react';
import Axios from 'axios';
import Endpoint from "../../settings/endpoints.json";
import { CookiesProvider, useCookies, Cookies } from 'react-cookie';



export default function Profile(){
  
  
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
  } = useAuth0();
  
  const {
    _currentUser,
    setCurrentUser,
    _currentCookie,
    setCurrentCookie
  } = useApp();
  
  const [cookies, setCookie, deleteCookie] = useCookies(['cmsfrontapp']);
  
  const [profileData, setProfileData] = useState({
    nickname_profile : '',
    email : '',
    day: '',
    month: '',
    year: ''
  });
  
  
  useEffect(() => {
    console.log(_currentUser);
    if( _currentUser !== null ) {
      setProfileData({
        nickname_profile : _currentUser.profile.nickname_profile,
        email : _currentUser.email,
        day: '',
        month: '',
        year: ''
      })
    }
  },[_currentUser])
  
  const handleSubmit = e => {
    e.preventDefault();
    console.log(profileData);
    
    Axios.post( Endpoint.api + '/usuario/profile', {
      user_id : _currentUser !== null ? _currentUser.id : null,
      id : _currentUser.profile.id,
      nickname_profile : profileData.nickname_profile
    }).then( function(response_profile) {
        Axios.post( Endpoint.api + '/usuario/sync', {
          data: user,
          iuuid: cookies.cmsfrontapp
        }).then( response => {
          if( typeof response.data.user !== 'undefined')
            setCurrentUser(response.data.user);
        }).catch( e => {
          console.log(e)
        })
    })
    // Axios.post(process.env.REACT_APP_API + '/api/v1/causa', causa )
    //      .then( function( response ) {
    //        toast.success('Causa creada correctamente!', {
    //           position: "top-center",
    //           autoClose: 5000,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //           onClose: () => {
    //             history.push({pathname:  "/causa/" + causa.id});
    //           } 
    //       });
    //       history.push({pathname:  "/causa/" + causa.id});
    //      })
    //      .catch( function(e){
    //        toast('Error');
    //        console.log(e)
    //      } );
  }

  const handleInputChange = (event) => {
      setProfileData({
          ...profileData,
          [event.target.name] : event.target.value
      })
  }
  
  return (
    <div className="container profile">
      <div className="section_profile">
        <figure>
          <img src={user.picture} alt=""/>
        </figure>
        <h3 className="name">{profileData.nickname_profile ?? '' }</h3>
      </div>
      <div className="section_profile">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="nickname_profile">Nombre</label>
            <input id="nickname_profile" className="form-control" type="text" value={profileData.nickname_profile ?? '' } onChange={handleInputChange} name="nickname_profile" />
          </div>
          
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input id="email" className="form-control" type="email" value={profileData.email ?? '' } onChange={handleInputChange} name="email" />
          </div>
          
          <div className="form-group">
            <label htmlFor="email">Fecha de nacimiento</label>
            <div className="flex_inputs">
              <input id="day" className="form-control" type="number" value={profileData.day ?? '' } onChange={handleInputChange} name="day" />
              <input id="month" className="form-control" type="number" value={profileData.month ?? '' } onChange={handleInputChange} name="month" />
              <input id="year" className="form-control" type="number" value={profileData.year ?? '' } onChange={handleInputChange} name="year" />
            
            </div>
          </div>
          
          <div className="form-group">
            <label htmlFor="email">Telefono:</label>
            <div className="flex_inputs">
              <input id="day" className="form-control" type="number" value={profileData.day ?? '' } onChange={handleInputChange} name="day" placeholder="+54" />
              <input id="month" className="form-control" type="number" value={profileData.month ?? '' } onChange={handleInputChange} name="month" placeholder="11"/>
              <input id="year" className="form-control" type="number" value={profileData.year ?? '' } onChange={handleInputChange} name="year" placeholder="000000"/>
            
            </div>
          </div>
          <input type="submit" className="btn sucess"/>
        
        </form>
      </div>
      
      <div className="section_profile">
        <button className="btn secondary logout" onClick={logout}>Cerrar sesión</button>
      </div>
      
      <div className="section_profile">
        <div className="baja">
          ¿Ya no quiere esta cuenta? <span className="link">Cierre esta cuenta</span>
        </div>
      </div>
      
      
      
    </div>
  )
}
