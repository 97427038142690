import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from "axios";
import { useApp } from '../../context/AppContext';
import Endpoint from "../../settings/endpoints.json";
import Track from '../track';
import { CookiesProvider, useCookies, Cookies } from 'react-cookie';

let Component = ( post ) => {
  
  const [dialog, setDialog] = useState('close');
  const [item, setItem] = useState('');
  const [is_loading_status, setIsLoadingStatus] = useState(true);
  const { _currentUser } = useApp();
  const [cookies, setCookie, deleteCookie] = useCookies(['cmsfrontapp']);
  
  var active = false;
  
  if( typeof post.post.extra != 'undefined' && typeof post.post.extra.allow_actions_guest != 'undefined'  ) {
    active = post.post.extra.allow_actions_guest == '1';
  }
  
  useEffect(()=>{
    if( _currentUser !== null ) {
      axios.post( Endpoint.api + '/usuario/has_react', {
        post_id : post.post.id,
        user_id : _currentUser.id,
        iuuid : cookies.cmsfrontapp
      }).then( response => {
        if( response.data.status == 'ok' ) {
          setItem(response.data.data)
        } 
        setIsLoadingStatus(false);
        
      })
      
    } else {
      setIsLoadingStatus(false);
    }
    
  })
  
  const {
    isAuthenticated
  } = useAuth0();
  
  function openDialog(e)
  {
    e.preventDefault();
    if( item == '' ) {
      setItem(e.target.dataset.item);
      setDialog('open')
    }
  }
  
  function sendReaction(e)
  {
    e.preventDefault();
    var content = e.target.querySelector('textarea').value;
    var reaction = item;
    e.target.reset();
    
    axios.post( Endpoint.api + '/usuario/react', {
      content: content,
      reaction: reaction,
      post_id : post.post.id,
      user_id : _currentUser !== null ? _currentUser.id : null,
      iuuid : cookies.cmsfrontapp
    }).then( response => {
      setDialog('close'); setItem('');
      Track.track({
        'event_category' : 'Post',
        'event_action'   : 'reaction',
        'event_label'    : post.post.id,
        'event_value'    : 1,
        'iuuid'          : cookies.cmsfrontapp,
        'content'        : content,
        'reaction'       : reaction,
        'post_id'        : post.post.id,
        'user_id'        : _currentUser !== null ? _currentUser.id : null,
      })
    }).catch( e => {
      setDialog('close');
    })
    
    // var data = e.target
  }
  
  if( isAuthenticated || active ) {
    return (
      <div className={'reactions ' + dialog + ( item != '' ? ' active-' + item + ' ': '' )}>
        <div className="close" onClick={() => { setDialog('close'); setItem(''); }}><i className="fa fa-times-circle"></i></div>
        <h2>¿Cómo evaluarías esto?</h2>
        <ul>
          <li>
            <a href="" data-item="1" onClick={openDialog}></a>
          </li>
          <li>
            <a href="" data-item="2" onClick={openDialog}></a>
          </li>
          <li>
            <a href="" data-item="3" onClick={openDialog}></a>
          </li>
          <li>
            <a href="" data-item="4" onClick={openDialog}></a>
          </li>
          <li>
            <a href="" data-item="5" onClick={openDialog}></a>
          </li>
        </ul>
        
        <div className="content">
          <form name="test" onSubmit={sendReaction}>
            <textarea name="mensaje" id="" cols="30" rows="10">
            </textarea>
            <button type="submit">Enviar</button>
          </form>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default Component