import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import qs from 'qs';

import ContentLoader from "react-content-loader";

import { useApp } from '../context/AppContext';
import { useHistory } from "react-router-dom";
import UAParser from "ua-parser-js";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';

import Endpoint from "../settings/endpoints";
import { CookiesProvider, useCookies, Cookies } from 'react-cookie';
import Track from './track';


const CategoryItemPlaceholder = () => (
  <ContentLoader height={55} width={55} viewBox="0 0 55 55"  backgroundColor="#DDD" speed={1}>
    
    <circle cx={55/2} cy={55/2} r={55/2} />
  
  </ContentLoader>
);


let ItemMenu = ( {data, slick} ) => {
  var history = useHistory();

  const [cookies, setCookie, deleteCookie] = useCookies(['cmsfrontapp']);
  const {_currentCategory, setCurrentCategory} = useApp();
  const [startX, setStartX] = useState(null);
  const [isDragging, setIsDragging] = useState(false);



  const handleMouseDown = (event) => {
    setStartX(event.clientX);
    setIsDragging(true);
  };

  const handleMouseUp = (event, cb) => {
    if (isDragging) {
      const endX = event.clientX;
      const distance = Math.abs(endX - startX);

      // Ajusta este umbral según tus necesidades
      const swipeThreshold = 50;

      if (distance < swipeThreshold) {

        
        // Es un clic, realiza la acción de clic
        console.log('Es un clic');
        cb()
      } else {
        const direction = distance > 0 ? 1 : -1;
        const distanceRatio = Math.abs(distance / swipeThreshold);
        const slidesToMove = Math.ceil(distanceRatio);

        /*
        if (slick.current) {
          if (direction === 1) {
            slick.current.slickPrev(slidesToMove);
          } else {
            slick.current.slickNext(slidesToMove);
          }
        }
        */
        // Es un swipe, realiza la acción de swipe
        console.log('Es un swipe');
      }

      setIsDragging(false);
    }
  };


  function itemSetCategory(data) {
    setCurrentCategory(data); 
  }
  return (
    <div className="menu_item" 
      

      onMouseDown={handleMouseDown}
      onMouseUp={(e) => {
        handleMouseUp(e, ()=>{
          itemSetCategory(data)
        })
      }}
    >
      
      <img src={ Endpoint.upload + data.icon } onError={(e) =>{
        e.target.style.display = 'none'
      }} />
      <strong>{data.nombre}</strong>
    </div>
  )
}

let ListCategories = ( {data} ) => {


  const sliderRef = useRef(null);
  
  let userAgent;
  userAgent = window.navigator.userAgent;
  
  const parser = new UAParser();
  parser.setUA(userAgent);
  const result = parser.getResult();
  const deviceType = (result.device && result.device.type) || "desktop";
  

  
  const options = {
      items: 6,
      responsiveClass:true,
      nav:false,
      center:false,
      navText:['<i class="fa-solid fa-chevron-left"></i>','<i class="fa-solid fa-chevron-right"></i>'],
      responsive:{
          0:{
              items:4,
              nav:true, 
              stagePadding: 30
          },
          1000:{
              items:10,
              nav:true,
              stagePadding: 60
          }
      }
  };

  if (data.length == 0) return null;
  return (
    <>

      <OwlCarousel options={options} >
      {
          data.map( (category, i ) => {
            return( <ItemMenu data={category} key={category.id} slick={sliderRef} /> );
          })
        }
      </OwlCarousel> 
     
    </>
  )
}

let ItemSearch = ( ) => {
  const {_currentSearch, setCurrentSearch} = useApp();
  const [item, setItem] = useState("");
  const [cookies, setCookie, deleteCookie] = useCookies(['cmsfrontapp']);
  useEffect(()=>{
    if( _currentSearch !== null )
      setItem(_currentSearch);
  },[]);

  console.log(_currentSearch)
  
  return (
    <div className="menu_item_active" onClick={() =>{ Track.track({
      'event_category' : 'search',
      'event_action'   : 'close',
      'event_label'    : item,
      'event_value'    : 1,
      'iuuid'          : cookies.cmsfrontapp
    }); setCurrentSearch(null);  window.location.href = '/';} }>
    <strong>
     {item}
    </strong>
    <span> x </span>
    </div>
  )
}

let ItemCategory = ( ) => {
  const {_currentCategory, setCurrentCategory} = useApp();
  const [category, setCategory] = useState({ nombre : null, id:null });
  const [cookies, setCookie, deleteCookie] = useCookies(['cmsfrontapp']);
  useEffect(()=>{
    if( _currentCategory !== null )
      setCategory(_currentCategory);
  },[]);
  
  return (
    <div className="menu_item_active" onClick={() =>{ Track.track({
      'event_category' : 'category',
      'event_action'   : 'close',
      'event_label'    : category.id,
      'event_value'    : 1,
      'iuuid'          : cookies.cmsfrontapp
    }); setCurrentCategory(null);  } }>
    <img src={ Endpoint.upload + category.icon} alt=""/>
    <strong>
      {category.nombre} 
    </strong>
    <span> x </span>
    </div>
  )
}

let ItemActive = ( ) => {
  const {_currentCategory, setCurrentCategory, _currentSearch, setCurrentSearch, _loadedCategories, setLoadedCategories} = useApp();
  if( _currentSearch != null ) {
    return( <ItemSearch />);
  }
  return( <ItemCategory />);
}



export default function Menu() {
  // Defino el seter para usar cuando reciba las categorias
  const {_currentCategory, _currentSearch, setCurrentCategory, _loadedCategories, setLoadedCategories} = useApp();
  const [categories, setCategory] = useState([]);
  const [showAllCategories, setShowAllCategories] = useState(false);
  useEffect( () => {
    if( _loadedCategories.length > 0) {
      setCategory(_loadedCategories)
      return;
    }
    axios.get(Endpoint.api + '/categorias' )
         .then( response => {
           setCategory( response.data.data );
           setLoadedCategories(response.data.data)
         })
         .catch( e => {
           console.log(e);
         })
  }, [] );
  useEffect( () => {
    
    setShowAllCategories( _currentCategory == null );

  }, [_currentCategory] );

  useEffect( () => {
    
    setShowAllCategories( _currentSearch == null );

  }, [_currentSearch] );
  
  return (
    <div className="category_menu">
      { showAllCategories ? <ListCategories data={categories} /> : <ItemActive data={_currentCategory} />}  
    </div>
  )
}

