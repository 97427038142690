import Axios from "axios";
import Endpoint from "../settings/endpoints.json";

class TrackService {
  track( data ) {
    return Axios.post(Endpoint.api + '/track', data)
         .then( response => {
            console.log(response);
         })
         .catch( e => {
         })
  } 
}

export default (new TrackService());