import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import {
    UserOutlined
} from '@ant-design/icons';
const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <UserOutlined onClick={() => loginWithRedirect()} style={{cursor:"pointer", fontSize: '26px'}} />;
};

export default LoginButton;