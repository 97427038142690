import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from "axios";
import { useApp } from '../../context/AppContext';
import Endpoint from "../../settings/endpoints.json";
import Track from '../track';
import { CookiesProvider, useCookies, Cookies } from 'react-cookie';

let Item = ( data ) => {
  console.log(data);
  return (
    <div className="Comment">
      <div className="header">
        {
          data.data.user == null &&
          <div className="user">Anonimo</div>
        }
        
        {
          data.data.user != null && typeof data.data.user.user_profile != 'undefined' && data.data.user.user_profile == null &&
          <div className="user">Usuario registrado sin perfil</div>
        }
        
        {
          data.data.user != null && typeof data.data.user.user_profile != 'undefined' && data.data.user.user_profile  != null &&
          <div className="user">{data.data.user.user_profile.nickname_profile}</div>
        }
        
        <div className="date">
          {data.data.created_at}
        </div>
      
      </div>
      
      <div className="content">
        {data.data.content}
      </div>
    </div>
  )
}

let Component = ( {data} ) => {
  let post = data;
  const [dialog, setDialog] = useState('close');
  const [item, setItem] = useState('');
  const [ready, setReady] = useState(false);
  const { _currentUser } = useApp();
  const [cookies, setCookie, deleteCookie] = useCookies(['cmsfrontapp']);
  
  var active = false;
  
  if( typeof post.extra != 'undefined' && typeof post.extra.allow_actions_guest != 'undefined'  ) {
    active = post.extra.allow_actions_guest == '1';
  }
  
  const {
    isAuthenticated
  } = useAuth0();

  useEffect(()=>{
    setReady(true);
  }, [post])

  if( ready ) {
    console.log(post.comments,1);
    return (
      <div className="comments">
       { typeof post.comments == 'object' && 
         post.comments.map( (item, i) => {
           return <Item key={i} data={item} />
         })
       }
      </div>
    );
  } else {
    return null;
  }
}

export default Component