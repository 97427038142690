import React, { useState, useEffect, useRef } from 'react';
import {  useParams, Link } from "react-router-dom";
import Axios from 'axios'
// import qs from 'qs';
import Reactions from './reactions'
import Comments from './comments'
import ReactHtmlParser from 'react-html-parser';
import ReactPlayer from 'react-player'
import Endpoint from "../../settings/endpoints";
import Track from '../track';
import { CookiesProvider, useCookies, Cookies } from 'react-cookie';
import SvgFacebook from '../../assets/img/icon-share-fb.svg';
import SvgLink from '../../assets/img/icon-share-link.svg';
import SvgWhatsapp from '../../assets/img/icon-share-wapp.svg';
import SvgMessenger from '../../assets/img/icon-share-mensseger.svg';
import SvgTwitter from '../../assets/img/icon-share-tw.svg';
import SvgEmail from '../../assets/img/icon-share-mail.svg';
import SvgSMS from '../../assets/img/icon-share-sms.svg';
import { CommentIcon, ShareIcon, SuscribeIcon } from '../../assets/icons';
import SvgSuscribirse from '../../assets/img/ico-suscribirse.svg';
import SvgCompartir from '../../assets/img/ico-share.svg';
import { useApp } from '../../context/AppContext'
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { Helmet } from 'react-helmet';
import ContentLoader from 'react-content-loader'
import { notification } from 'antd';

const ArticleLoader = props => (
  <ContentLoader
    viewBox="0 0 850 1500"
    backgroundColor="#eaeced"
    foregroundColor="#ffffff"
    style={{width:'100%', padding:'10px'}}
    {...props}
  >
    <rect x="0" y="0" rx="0" ry="0" width="100%" height="291" />
    <rect x="359" y="457" rx="4" ry="4" width="150" height="10" />
    <rect x="48" y="515" rx="4" ry="4" width="720" height="15" />
    <rect x="49" y="547" rx="4" ry="4" width="598" height="15" />
    <rect x="48" y="581" rx="4" ry="4" width="720" height="15" />
    <rect x="49" y="612" rx="4" ry="4" width="520" height="15" />
    <rect x="48" y="652" rx="4" ry="4" width="720" height="15" />
    <rect x="48" y="684" rx="4" ry="4" width="598" height="15" />
    <rect x="48" y="718" rx="4" ry="4" width="720" height="15" />
    <rect x="49" y="748" rx="4" ry="4" width="419" height="15" />
    <circle cx="713" cy="810" r="9" />
    <circle cx="739" cy="810" r="9" />
    <rect x="41" y="836" rx="4" ry="4" width="720" height="3" />
    <rect x="122" y="880" rx="4" ry="4" width="320" height="11" />
    <circle cx="79" cy="900" r="26" />
    <rect x="135" y="901" rx="4" ry="4" width="120" height="10" />
    <rect x="123" y="949" rx="4" ry="4" width="320" height="11" />
    <circle cx="80" cy="969" r="26" />
    <rect x="136" y="970" rx="4" ry="4" width="120" height="10" />
    <rect x="124" y="1021" rx="4" ry="4" width="320" height="11" />
    <circle cx="81" cy="1041" r="26" />
    <rect x="137" y="1042" rx="4" ry="4" width="120" height="10" />
    <rect x="125" y="1090" rx="4" ry="4" width="320" height="11" />
    <circle cx="82" cy="1110" r="26" />
    <rect x="138" y="1111" rx="4" ry="4" width="120" height="10" />
  </ContentLoader>
)


let ItemEncuesta = ( {data} ) => {
  
  return (
    <div className="container encuesta">
      <div className="post_view">
        <iframe src={data} frameborder="0"></iframe>
      </div>
    </div>
  )
}

let ItemPost = ( {data} ) => {
  let post = data;
  const [cookies, setCookie, deleteCookie] = useCookies(['cmsfrontapp']);


  const {
    _currentUser,
    setCurrentUser,
    _currentCookie,
    setCurrentCookie,
    _currentView,
    setCurrentView,
    loadContent,
    setLoadContent
  } = useApp();
  
  function openEncuesta(e, url)
  {
    e.preventDefault();
    e.stopPropagation();
    setCurrentView({view: 'encuesta', data:url});
  } 

  // <Reactions post={post} /> 
  
  return (
    <div className="post_view">
      {
        post.extra != null && typeof post.extra.url_youtube != 'undefined' && post.extra.url_youtube != '' &&
      
        <div className='player-wrapper'>
          <ReactPlayer
            className='react-player'
            url={post.extra.url_youtube}
            width='100%'
            height='100%'
            muted={true}
            autoplay={true}
            playing={true}
            controls={true}
          />
        </div>
      }
      {
        post.extra != null && ( typeof post.extra.url_youtube == 'undefined' || post.extra.url_youtube == '' ) &&
        <div className='image-wrapper'>
          <figure style={{backgroundImage: 'url("' + Endpoint.upload + post.image + '")'}}>
            <img src={ Endpoint.upload + post.image } alt={post.title} />
          </figure>
        </div>
      }
      <div className="body">
        <div className='fecha'>
          {post.fecha}
        </div>
        <div className="content">
          {ReactHtmlParser(post.content)}
          
        </div>
        
        {
          post.extra!= null && typeof post.extra.body != 'undefined' && typeof post.extra.body.encuesta != 'undefined' && post.extra.body.encuesta.titulo != ''  &&
          <div className="cta">
            <h1>{post.extra.body.encuesta.titulo}</h1>
            <div className="desc">
              {post.extra.body.encuesta.description}
            </div>
            <a href={post.extra.body.encuesta.url + '?_iuuid=' + cookies.cmsfrontapp} target="_blank" onClick={(e) => openEncuesta(e,post.extra.body.encuesta.url)} >
              {post.extra.body.encuesta.label}
            </a>
          </div>
        }
      </div>
      {
        post.comments !== null && post.comments != '' && 
        <Comments data={post} />
      }
       <Reactions post={post} /> 
      <Toolbar data={post}/>
      
    </div>
  );
}

let Toolbar = ({data}) => {

  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [isVisible, setIsVisible] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  
  const openNotification = ( placement ) => {

    api.success({
      message: `Gracias por tu comentario`,
      description:
        'Tu opinión ha sido recibida correctamente. Apreciamos tu participación y la tendremos en cuenta. ¡Gracias! ',
      placement,
    });
  };

  const {
    _modalOpen, setModalOpen
  } = useApp();
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos =  window.pageYOffset;

      if(  _modalOpen ) {
        setIsVisible(false);
        return;
      }
      setIsVisible(prevScrollPos < (currentScrollPos - 100) || currentScrollPos > 200);

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  let post = data;
  const {
    _currentUser,
    setCurrentUser,
    _currentCookie,
    setCurrentCookie
  } = useApp();
  
  const [modalshare, setModalShare] = useState(false);
  const [modalsuscribe, setModalSuscribe] = useState(false);
  const [modalcomment, setModalComment] = useState(false);
  const [cookies, setCookie, deleteCookie] = useCookies(['cmsfrontapp']);
  
  let textInputComment = useRef(null);
  
  const {
    isLoading,
    isAuthenticated,
    user
  } = useAuth0();
  
  function buildUrlMessenger(link)
  {
    let text = 'Mira este post';
    link = window.location.href;
    return 'http://www.facebook.com/dialog/send?app_id=2207870592689818&link=' + encodeURIComponent(link) + '&text=' + encodeURIComponent(post.title);
  } 
  
  function buildUrlTwitter(link)
  {
    var text = 'Mira este post';
    link = window.location.href;
    return 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(link) + '&text=' + encodeURIComponent(post.title);
  } 

  function buildUrlEmail(link)
  {
    var title = `Mira esta nota: ${post.title}`;
    link = window.location.href;
    var contenido = post.content.replace(/(<([^>]+)>)/ig, '').substr(0, 200) + '... Leé más en: ' +  link;

    return 'mailto:?subject='+ encodeURIComponent(title) +'&body='+ encodeURIComponent(contenido);
  } 

  function buildUrlWhatsapp(link)
  {
    link = window.location.href;  
    return 'https://wa.me/?text=' + encodeURIComponent(post.title + ' Leé más en: ' + link);
  } 

  function buildUrlSms(link)
  {
    link = window.location.href;
    return 'sms:?&body=' + encodeURIComponent(post.title + ' Leé más en: ' + link);
  } 
  
  function suscribe()
  {
    Axios.post( Endpoint.api + '/usuario/suscription', {
      user_id : _currentUser !== null ? _currentUser.id : null,
      iuuid : cookies.cmsfrontapp
    }).then( response => {
      Axios.post( Endpoint.api + '/usuario/sync', {
        data: user,
        iuuid: cookies.cmsfrontapp
      }).then( response => {
        if( typeof response.data.user !== 'undefined')
          setCurrentUser(response.data.user);
      }).catch( e => {
        console.log(e)
      })
    });
  }
  
  function sendMessage(post)
  {
    var comment = textInputComment.current.value;
    textInputComment.current.value = '';
    Axios.post( Endpoint.api + '/usuario/comment', {
      content: comment,
      post_id : post.id,
      user_id : _currentUser !== null ? _currentUser.id : null,
      iuuid : cookies.cmsfrontapp
    }).then( response => {
      openNotification('bottomRight');
      setModalComment(false);
    }).catch( e => {
      openNotification('bottomRight');
      setModalComment(false);
    })
  }

  const ref = useRef(null);

  useEffect(() => {
    
    const handleResize = () => {
        if(  modalshare ) {
          ref.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
          console.log('resize')
    
        }
    }

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  

  useEffect(() => {
    setModalOpen(modalshare)
  }, [modalshare]);

  let link = window.location.href;
  
  const dispatchLink = (e) => {
    e.preventDefault();
    var url = e.target.href;
    setModalShare(false);
    window.open(url);
  }
  return(
    <div className={'actions'} >
    <div className={"modal share " + ( modalshare ? ' open ' : '')}>
        
        <div className="content">
          <h2>Compartí este contenido:</h2>
          <ul>


            <li>
              <a onClick={dispatchLink} href={buildUrlWhatsapp(Endpoint.upload + "/news/ver/" + post.id)} className="whatsapp" target="_blank">
                <img src={SvgWhatsapp} />
                Enviar por WhatsApp
              </a>
            </li>
            <li>
              <a onClick={dispatchLink} href={buildUrlTwitter(Endpoint.upload + "/news/ver/" + post.id)} className="twitter" target="_blank">
              <img src={SvgTwitter} />
              Tuitear a tus seguidores</a>
            </li>
            <li>
              <a onClick={dispatchLink} href={buildUrlSms(Endpoint.upload + "/news/ver/" + post.id)} className="smms">
              <img src={SvgSMS} />
              Enviar un mensaje de texto</a>
            </li>
            <li>
              <a href="#" onClick={(e) => { e.preventDefault(); setModalShare(false);}}>Cancelar</a>
            </li>
            <li ref={ref}></li>
          </ul>
        </div>
      
      </div>
      
      <div className={"modal comment " + ( modalcomment ? ' open ' : '')}>
        
        <div className="content">
          <h2>Dejanos tus comentarios sobre esto:</h2>
          <textarea name="comment" id="" cols="30" rows="10" ref={textInputComment}></textarea>
          <button className="submit"   onClick={(e)=>{e.preventDefault(); sendMessage(post)  }}>Enviar</button>
          <button className="cancelar" onClick={(e)=>{e.preventDefault(); setModalComment(false)}}>Cancelar</button>
        </div>
      
      </div>
      
      <div className={isVisible ? 'toolbar visible' : ' toolbar oculto'}>
        <button onClick={()=>{
          setModalShare(true);
        }}
        >
        <ShareIcon  />
        <span>Compartir</span>
        </button>
      {
        _currentUser !== null &&

        <button onClick={()=>{
          suscribe();
        }}
        >
        {
          _currentUser._is_sucripto == 0 &&
          <div>
          <SuscribeIcon  />
          <span>Suscribirse</span>
          </div>
          
        }
        {
          _currentUser._is_sucripto == 1 &&
          <div className="suscripto">
          <SuscribeIcon  />
          <span>Suscripto</span>
          </div>
        }
        
        </button>

     
      }

        
      </div>

    </div>
  );
}

export default function PostVer()
{
  let {id} = useParams();
  const [post, setPost] = useState({ id : null});
  const [allow_actions_guest, setAllowActionGuest] = useState(false);
  const [content_ready, setContentReady] = useState(false);
  const [cookies, setCookie, deleteCookie] = useCookies(['cmsfrontapp']);
  const {
    _currentUser,
    setCurrentUser,
    _currentCookie,
    setCurrentCookie,
    _currentView,
    setCurrentView,
    _currentSite,
    loadContent,
    setLoadContent,
    _modalOpen, setModalOpen
  } = useApp();
  const {
    isLoading,
    isAuthenticated,
    user
  } = useAuth0();
  const [modalshare, setModalShare] = useState(false);
  const [modalsuscribe, setModalSuscribe] = useState(false);
  const [modalcomment, setModalComment] = useState(false);
  let textInputComment = useRef(null);
  
  function buildUrlMessenger(link)
  {
    return 'http://www.facebook.com/dialog/send?app_id=2207870592689818&link=' + encodeURIComponent(link) 
  } 
  
  function buildUrlTwitter(link)
  {
    return 'https://twitter.com/intent/tweet?counturl=' + encodeURIComponent(link) + 'text=Bajate%20la%20App%20de%20@UCR&hashtags=UCR';
  } 

  function buildUrlEmail(link)
  {
    return 'mailto:?subject=App%20UCR&body=Contenido%20a%20compartir%20definan%20cual...';
  } 

  function buildUrlWhatsapp(link)
  {
    return 'https://wa.me/?text=' + encodeURIComponent('mira este post ' + link);
  } 

  function buildUrlSms(link)
  {
    return 'sms:?body=' + encodeURIComponent('mira este post ' + link);
  } 
  function suscribe()
  {
    Axios.post( Endpoint.api + '/usuario/suscription', {
      user_id : _currentUser !== null ? _currentUser.id : null,
      iuuid : cookies.cmsfrontapp
    }).then( response => {
      Axios.post( Endpoint.api + '/usuario/sync', {
        data: user,
        iuuid: cookies.cmsfrontapp
      }).then( response => {
        if( typeof response.data.user !== 'undefined')
          setCurrentUser(response.data.user);
      }).catch( e => {
        console.log(e)
      })
    });
  }
  function sendMessage(post)
  {
    var comment = textInputComment.current.value;
    textInputComment.current.value = '';
    Axios.post( Endpoint.api + '/usuario/comment', {
      content: comment,
      post_id : post.id,
      user_id : _currentUser !== null ? _currentUser.id : null,
      iuuid : cookies.cmsfrontapp
    }).then( response => {
      setModalComment(false)
    }).catch( e => {
      setModalComment(false)
    })
  }
  
  function openEncuesta(e, url)
  {
    e.preventDefault();
    e.stopPropagation();
    alert(url);
  } 

  useEffect(()=>{
    setLoadContent(false);
    function fetchItems() {
    
      Axios.get(Endpoint.api + '/posts/ver/' + id)
           .then( response => {
             if(response.data.status === 'ok') {
               setPost(response.data.data);
               Track.track({
                 'event_category' : 'Post',
                 'event_action'   : 'page_view',
                 'event_label'    : id,
                 'event_value'    : 1,
                 'iuuid'          : cookies.cmsfrontapp
               })
               if( typeof response.data.extra != 'undefined' && typeof response.data.extra.allow_actions_guest != 'undefined'  ) {
                 setAllowActionGuest( response.data.extra.allow_actions_guest == '1' );
               }
               setLoadContent(true); 
               setContentReady(true);
               setCurrentView({view: 'post', data:response.data.data});
    
             }
           })
           .catch( e => {
             console.log(e);
           })
    }
    fetchItems()
  },[])
  
  if( content_ready ) {
    
      return (
        <div className="container post">
          <div className="section_title">
            <h2>{post.categorias.name}</h2>
            {
            post.extra != null && typeof post.extra.subtitle != 'undefined' && post.extra.subtitle != '' 
              ?
              <h3>por {post.extra.subtitle}</h3>
              :
              <h3>por Gobierno del Chubut</h3>
            }
            
          </div>
          {
            typeof _currentView.view != 'undefined' && _currentView.view == 'post' &&
            <ItemPost data={_currentView.data}/>    
          }
          
          {
            typeof _currentView.view != 'undefined' && _currentView.view == 'encuesta' &&
            <ItemEncuesta data={_currentView.data}/>    
          }
      </div>
    )
  } else {
    return (
      <div className="container post">
          <div className="post_view">
            <ArticleLoader />
          </div>
      </div>
    )
  }
}
