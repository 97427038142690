import React, { useState, useEffect, useSearchParams } from "react"

import {  useParams, Link } from "react-router-dom";
import Endpoint from "../../settings/endpoints";
import Menu from '../menu.js';
import { useApp } from '../../context/AppContext'
import Axios from 'axios'
import qs from 'qs';
import ReactHtmlParser from 'react-html-parser';
import { Instagram } from 'react-content-loader'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import { MasonryGrid, JustifiedGrid, FrameGrid, PackingGrid } from "@egjs/grid";
import InfiniteScroll from "react-infinite-scroll-component";


let ItemPrivate = ( {data, show_text} ) => {
  console.log(data.extra);
  if( typeof data.extra != 'undefined' &&  typeof data.extra.url_encuesta != 'undefined' ) {
    return(
      <div className="item post private">
      <h2>{data.title}</h2>
      <div className="desc">
      { ReactHtmlParser(data.excerpt) }
      </div>
      <a href={ data.extra.url_encuesta} target="_blank" className="link">
        Contestar encuesta
      </a>
      </div>
    )
  } else {
    return null
  }
}

let Item = ( {data, show_text} ) => {
  var n = Math.random();

  function quitarAcentos(texto) {
    return texto
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  }


  const generarSlug = (titulo) => {
    return quitarAcentos(titulo)
      .toLowerCase() // Convierte a minúsculas
      .replace(/\s+/g, '-') // Reemplaza espacios con guiones
      .replace(/[^\w\-]+/g, '') // Elimina caracteres no alfanuméricos y no guiones
      .replace(/\-\-+/g, '-') // Reemplaza múltiples guiones con uno solo
      .replace(/^-+/, '') // Elimina guiones al principio
      .replace(/-+$/, ''); // Elimina guiones al final
  };
  var slugs = {
    'eje' : generarSlug(data.categorias.name),
    'titulo' : generarSlug(data.title)
  }  
  var link = `/notas/${slugs.eje}/${data.id}/${slugs.titulo}.html`;

  return (
    <div className="item post">
    
      <div className="header">
        <img src={ Endpoint.upload + data.categorias.image_url} />
        <Link to={link} className="link">
          <div className="title">
            <h2> {data.categorias.name}</h2>
            { 
              data.extra != null && typeof data.extra.subtitle != 'undefined' && data.extra.subtitle != '' 
              ?
                <h3>por {data.extra.subtitle}</h3>
              :
                <h3>por Gobierno del Chubut</h3>
            }
          </div>
        </Link>
      </div>
    
      <Link to={link} className="link">
        <figure>
            <img src={Endpoint.upload + data.image } alt={data.title} />
        </figure>
      </Link>
      
      <div className="description">
        <Link to={link} className="link">
          { ReactHtmlParser(data.excerpt) }
        </Link>
      </div>
    </div>
  )
}

let Encuesta = ({ data }) => {
  return (
    <div className="item cta">
      <h1>{data.extra.content.encuesta.titulo}</h1>
      <h2>{data.extra.content.encuesta.description}</h2>
      <a href={data.extra.content.encuesta.url + '&uuid=6asd6v6a6v6335fg'}>Contestar encuesta</a>
    </div>
  )
}

let Banner = ({ data }) => {
  console.log(data, 'banner');
  return (
    <div className="item banner">
      { data.extra.content.banner.url != '' &&
        <a href={data.extra.content.banner.url} target="_blank">
            <figure>
              <img src={Endpoint.upload + data.extra.content.banner.image} alt=""/>
            </figure>
        </a>
      }
      { data.extra.content.banner.url == '' &&
            <figure>
              <img src={Endpoint.upload + data.extra.content.banner.image} alt=""/>
            </figure>
      }
    </div>  
  )
}

export default function Post()
{
  const [posts, setPosts] = useState([]);
  

  
  const [isLoading, setIsLoading] = useState(false);
  const {_currentCategory, _currentSearch, setCurrentView, allPosts, setAllPosts, setCurrentSearch} = useApp();

  const url = new URL(window.location.href);
  const layoutDefinition = url.searchParams.get('layoutDefinition' ) ?? 'natural';

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  let {termino} = useParams();
  const Components  = {
    post : Item,
    encuesta : Encuesta,
    banner: Banner
  }

  
  const fetchPosts = () => {
    setPosts([]);
    setCurrentPage(1);
    
    var endpoint = '/posts/?q=' + termino + '&';
    console.log(termino )
    Axios.get(Endpoint.api + `${endpoint}page=${currentPage}`)
           .then( response => {
            console.log(response);
            setPosts(prevPosts => [...prevPosts, ...response.data.data.data]);
            setHasMore(currentPage < response.data.data.last_page);
            setLoading(false);
           })
           .catch( e => {
             setIsLoading(false);
             setPosts([]);
             console.log(e);
           })
  };

  const fetchMoreData =  ()=>{

    var endpoint = '/posts/?q=' + termino + '&';
    
     Axios.get(Endpoint.api + `${endpoint}page=${currentPage+1}`)
     .then( response => {
      console.log(response);
      setPosts(prevPosts => [...prevPosts, ...response.data.data.data]);
      setHasMore(currentPage < response.data.data.last_page);
      setLoading(false);
     })
     .catch( e => {
       setIsLoading(false);
       setPosts([]);
       console.log(e);
     })
     setCurrentPage(currentPage+1);
        
}


useEffect(()=>{
  setCurrentSearch(termino)
  setCurrentPage(1);
  fetchPosts()
},[])
  
  return (
    <div className="main-content" data-layout={layoutDefinition} >
      <Menu />
      { isLoading && 
        <div className="placeholder flex">
        <Instagram/>
        <Instagram/>
        <Instagram/>
        </div>
      }
      <InfiniteScroll
        dataLength={posts.length}
        next={fetchMoreData}
        hasMore={hasMore}
      > 
      <ResponsiveMasonry className="posts_grid" 
            >
            <Masonry className="post_grid" gutter={20}>
              {posts.map( (item, i) => {
                if (typeof Components[item.type] !== "undefined") {
                  return React.createElement(Components[item.type], {
                    key: item.id,
                    data: item
                  })
                }
              })}
            </Masonry>
      </ResponsiveMasonry>
      </InfiniteScroll>

    
    </div>
  )
}