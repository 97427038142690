import React, {useEffect} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation
} from "react-router-dom";

import PrivateRoute from './components/auth/PrivateRoute';
import Endpoint from "./settings/endpoints";
import Perfil from './components/auth/profile';
import Post from './components/post/post.js';
import PostVer from './components/post/ver.js';
import PostSearch from './components/post/search.js';
import Header from './components/layout/header.js';
import ScrollToTop from './components/scroll.js';
import { AppProvider, useApp } from './context/AppContext';
import { useAuth0 } from "@auth0/auth0-react";
import { CookiesProvider, useCookies, Cookies } from 'react-cookie';
import axios from "axios"
// Estilos 
import './assets/scss/app.scss';

console.log(process.env); 
const AppUrlListener = () => {
  const [cookies, setCookie, deleteCookie] = useCookies(['cmsfrontapp']);

  const {
    _currentUser,
    setCurrentUser,
    _currentCookie,
    setCurrentCookie,
    _themeConfig,
    _modalOpen,
    setCurrentSearch
  } = useApp();
  
  const {
    isLoading,
    isAuthenticated,
    user
  } = useAuth0();
  
  
  var history = useHistory();
 

  useEffect(() => {
    if( isAuthenticated ) {
      // Si esta autenticado verifica con la api si tiene un id para ese usuario sino lo genera y devuelve el nuevo id 
      axios.post( Endpoint.api + '/usuario/sync', {
        data: user,
        iuuid: cookies.cmsfrontapp
      }).then( response => {
        if( typeof response.data.user !== 'undefined')
          setCurrentUser(response.data.user);
      }).catch( e => {
        console.log(e)
      })
    }
  }, [isAuthenticated]);

  
  useEffect(() => {
    if( typeof cookies.cmsfrontapp == 'undefined' ) {
      var uuid =  '_' + Math.random().toString(36).substr(2, 9);
      setCookie('cmsfrontapp', uuid, { path : '/'});
    }

 
  }, []); 

  useEffect(()=>{
      document.body.classList.add(_themeConfig.theme)
      return () => {
        document.body.classList.remove(_themeConfig.theme);
      }
  }, [_themeConfig.theme])

  useEffect(()=>{
    if(_modalOpen == true ) {

      document.body.classList.add("modal_open")
    } else {

      document.body.classList.remove("modal_open");
    }
    return () => {
      document.body.classList.remove("modal_open");
    }
}, [_modalOpen])

  return null;
};

function App() {

  const { isLoading, error } = useAuth0();

  

  return (
    
    <CookiesProvider>

        <AppProvider>
          <AppUrlListener></AppUrlListener>
          <Router>
            <ScrollToTop />
            <div className="App">
              <Header />
              <Switch>
                <Route exact path="/" component={Post} />
                <PrivateRoute exact path="/mi-perfil" component={Perfil} />
                <Route path="/notas/:eje/:id/:titulo.html" component={PostVer} />
                <Route path="/buscar/:termino" component={PostSearch} />
              </Switch>
            </div>
          </Router>      
        </AppProvider>
    </CookiesProvider>
  );
}

export default App;
