import React from 'react';

export const CommentIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.01 72.7"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path class="toolbar_icon" d="M66,0H9.1A9.13,9.13,0,0,0,0,9.1V46.2a9.14,9.14,0,0,0,9.1,9.11h4.5L30.7,72.7V55.3H65.9A9.13,9.13,0,0,0,75,46.2V9.1A9,9,0,0,0,66,0ZM18.2,33.4a5.7,5.7,0,1,1,5.7-5.7A5.7,5.7,0,0,1,18.2,33.4Zm19.3,0a5.7,5.7,0,1,1,5.7-5.7A5.7,5.7,0,0,1,37.5,33.4Zm19.4,0a5.7,5.7,0,1,1,5.7-5.7A5.76,5.76,0,0,1,56.9,33.4Z"/></g></g></svg>
  )
}

export const ShareIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84 54"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path class="toolbar_icon" d="M52,0,84,25,52,50V37c-1.7-.08-31.38-1-52,17C8.25,28.57,34.17,17.51,52,13Z"/></g></g></svg>
  )
}

export const SuscribeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.1 92.3"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path class="toolbar_icon" d="M36.55,92.3a11.51,11.51,0,0,0,11.36-9.61H25.18A11.53,11.53,0,0,0,36.55,92.3Z"/><path d="M67.82,64.88C60.3,56.42,60.3,42.44,60.3,33.21V31c0-.67,0-1.31-.08-2C59.49,16.79,51.49,10.29,43.6,8a5.79,5.79,0,0,0,.06-.83,7.12,7.12,0,1,0-14.23,0,5.79,5.79,0,0,0,.06.83c-7.88,2.27-15.88,8.77-16.62,21,0,.65-.06,1.29-.08,2v2.19c0,9.23,0,23.21-7.5,31.63C4.74,65.46-.24,70.92,0,75.46v3.38H73.09V75.57c.25-4.65-4.73-10.11-5.27-10.69Z"/></g></g></svg>
  )
}