import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const PrivateRoute = ({component: Component, ...rest}) => {
  const {
    isLoading,
    isAuthenticated
  } = useAuth0();
  
    
    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            !isLoading
            ?
            (
              isAuthenticated ?
                <Component {...props} />
              : <Redirect to="/" />
            )
            :
            <div>Loading..</div>
        )} />
    );
  
  
}

export default PrivateRoute;