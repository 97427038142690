import React, { useState, useEffect, useRef } from "react"
import ImageLogo from "../../components/logo"
import ImageFlecha from "../../assets/img/flecha.png"
import { withRouter } from "react-router";
import { Route, Redirect } from 'react-router-dom';

import {Animated} from "react-animated-css";
import {Link} from "react-router-dom";
import LoginButton from "../auth/login";
import { useAuth0 } from '@auth0/auth0-react';
import { useApp } from '../../context/AppContext';
import { Dropdown, Drawer, Button, Space, FloatButton, Input} from 'antd';
import MenuIcon from '../../assets/img/search_menu.svg';
import {
  MenuOutlined ,
  UserOutlined,
  FacebookOutlined,
  TwitterOutlined,
  YoutubeOutlined,
  InstagramOutlined,
  CloseOutlined,
  CommentOutlined, CustomerServiceOutlined
} from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';

const Header = withRouter( (props) =>{
  let path = props.location.pathname.match(/\/notas\//);
  const history = useHistory();
  const location = useLocation();
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleBack, setIsVisibleBack] = useState(false);

  
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
  } = useAuth0();
  
  const {
    setThemeConfig,
    loadContent,
    _currentView,
    _modalOpen,
    setCurrentSearch
  } = useApp();

  useEffect(() => {
    const handleScroll = () => {

      if( _modalOpen ) {
        setIsVisible(true);
        return;
      }
      const currentScrollPos = window.pageYOffset;
      setIsVisible(currentScrollPos > prevScrollPos && currentScrollPos > 80);


      setIsVisibleBack(currentScrollPos > window.innerHeight);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    if( _modalOpen ) {
      setIsVisible(true);
      return;
    } else {
      setIsVisible(false);
      return;
    }

  }, [_modalOpen]);

  const { Search } = Input;

  const OnSearch = (value, _e, info) => {
    setCurrentSearch(value);
    window.location.href = '/buscar/' + encodeURIComponent(value);
  }

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  function changeTheme(e) {
    console.log(e);
    setThemeConfig({ theme: e.target.value })
  }

  var back = path == null ? 'back' : '';
  var visible = isVisible ? ' hide' : '';
  var visibleBack = isVisibleBack ? ' show' : '';

  const items = [
    {
      key: '2',
      label: (
        <a onClick={(e) => { e.preventDefault(); logout({ logoutParams: { returnTo: window.location.origin }})}}>
          Cerrar sesión
        </a>
      )
    }
  ];
  return(
    <div className="componentHeader">
    <header className={ back + ' ' + visible}>
      <div  >
        <img src={MenuIcon} onClick={(e)=>{ e.preventDefault(); setOpen(true)}} style={{cursor:"pointer", padding: '0', width:'30px'}}/>
       
      </div>
      <Link to="/" className="logoContainer" >
        <ImageLogo className="logosite"  />
      </Link>
      <div  >
          {!isLoading && isAuthenticated && (
              <Dropdown menu={{
                items,
              }}>
               <a onClick={(e) => e.preventDefault()}>
                <img
                  src={user.picture}
                  alt="Profile"
                  className="nav-user-profile d-inline-block rounded-circle mr-3"
                  width="50"
                  />
                </a>
              </Dropdown>
            
          )}
          {!isLoading && !isAuthenticated && (
            <LoginButton />
          )}

        </div>
    </header>
    { typeof _currentView.view !== 'undefined' && _currentView.view == 'home' &&
    
      <FloatButton.BackTop />
    }
    <Drawer
        placement={"left"}
        closable={false}
        onClose={onClose}
        open={open}
        key={1}
      >
        <div className="close">
          <CloseOutlined onClick={(e)=>{ e.preventDefault(); setOpen(false)}} style={{cursor:"pointer"}}/>
        </div>
        <Search
          placeholder="Buscar notas"
          onSearch={OnSearch}
          style={{
            margin: "0em 0 2em"
          }}
        />
        <div className="social">
          <Link  target="_blank" to={{ pathname: "https://www.facebook.com/Gobiernodelchubut" }}>
            <i class="espaciado fa-brands fa-facebook-f fa-2x" aria-hidden="true"></i>
          </Link>
          <Link  target="_blank" to={{ pathname: "https://www.instagram.com/chubutgobierno/" }}>
            <i class="espaciado fa-brands fa-square-instagram fa-2x" aria-hidden="true"></i>
          </Link>
          <Link  target="_blank" to={{ pathname: "https://twitter.com/gobiernochubut" }}>
            <i class="espaciado fa-brands fa-twitter fa-2x" aria-hidden="true"></i>
          </Link>
          <Link  target="_blank" to={{ pathname: "https://www.youtube.com/channel/UCkJA-sztIvr5yMmXBPnoAYQ" }}>
            <i class="espaciado fa-brands fa-youtube fa-2x" aria-hidden="true"></i> 
          </Link>
        </div>

        <div className="links">
          <Link  target="_blank" to={{ pathname: "https://boletin.chubut.gov.ar/" }}>
          
            Boletin oficial  
          </Link>
          <Link  target="_blank" to={{ pathname: "https://sistemas.chubut.gov.ar/digesto/sistema/consulta.php" }}>
            Digesto Digital  
          </Link>
          <Link  target="_blank" to={{ pathname: "https://licitaciones.chubut.gov.ar/" }}>
            Licitaciones  
          </Link>
          <Link  target="_blank" to={{ pathname: "https://sistemas.chubut.gov.ar/sippe/sistema/" }}>
            Recibo de Sueldos  
          </Link>
          <Link  target="_blank" to={{ pathname: "https://sistemas.chubut.gov.ar/expedientes" }}>
            Sistema de Expedientes 
          </Link>
          <Link  target="_blank" to={{ pathname: "https://sistemas.chubut.gov.ar/digesto/sistema/consulta.php" }}>
            Sistema de Viaticos  
          </Link>
          <Link  target="_blank" to={{ pathname: "https://sistemas.chubut.gov.ar/sippe/sistema/" }}>
            Sistema de Pago a Proveedores  
          </Link>
          <Link  target="_blank" to={{ pathname: "https://mail.chubut.gov.ar/" }}>
            Webmail  
          </Link>
        </div>
      </Drawer>
    <div className="main-content">
      { path != null &&
        <Link to="/" className="handler_back" style={{opacity: loadContent ? 100: 0}}> <img src={ImageFlecha} /></Link>
      }
    </div>
    </div>
    
  )
});

export default () => {
  return(
    <Header />
  )
}