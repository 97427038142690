import React, { useState } from 'react';

const AppContext = React.createContext();

export function AppProvider(props) {
  const [_loadedCategories, setLoadedCategories] = useState([]);
  const [_currentCategory, setCurrentCategory] = useState(null);
  const [_currentUser, setCurrentUser] = useState(null);
  const [_currentCookie, setCurrentCookie] = useState(null);
  const [_currentView, setCurrentView] = useState({view:null, data:null});
  const [_currentSite, setCurrentSite] = useState('Chubut')
  const [_themeConfig, setThemeConfig] = useState({ theme: 'light'});
  const [loadContent, setLoadContent] = useState(false);
  const [_cache, setCache] = useState(null);
  const [posts, setArticles] = useState([]);
  const [cachedArticles, setCachedArticles] = useState({});
  const [allPosts, setAllPosts] = useState([]);
  const [_modalOpen, setModalOpen] = useState(false);
  const [_currentSearch, setCurrentSearch] = useState(null);

  
  const value = {
    _currentCategory,
    setCurrentCategory,
    _loadedCategories, 
    setLoadedCategories,
    _cache,
    setCache,
    _currentUser,
    setCurrentUser,
    _currentCookie,
    setCurrentCookie,
    _currentView,
    setCurrentView,
    _currentSite,
    setCurrentSite,
    _themeConfig,
    setThemeConfig,
    loadContent,
    setLoadContent,
    updateArticles : (newArticles) => {
      setArticles(newArticles);
    },
    getArticleById : (id) => {
      return cachedArticles[id] || null;
    },
    updateCachedArticle : (id, data) => {
      setCachedArticles((prevCache) => ({
        ...prevCache,
        [id]: data,
      }));
    },
    posts,
    allPosts,
    setAllPosts,
    _modalOpen, setModalOpen,
    _currentSearch, setCurrentSearch
  }
  return <AppContext.Provider value={value} {...props} />
}

export function useApp() {
  const context = React.useContext(AppContext);
  if( ! context ) {
    throw new Error('useApp debe estar dentro del provider AppProvider');
  }
  return context;
}